import { PANCAKE_EXTENDED } from 'config/constants/lists'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { selectorByUrlsAtom } from 'state/lists/hooks'
import { useTokenDatasSWR, useAllTokenHighLight } from 'state/info/hooks'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChainId } from '@madmeerkatfinance/sdk'

export const useBSCWhiteList = () => {
  const listsByUrl = useAtomValue(selectorByUrlsAtom)
  const { current: list } = listsByUrl[PANCAKE_EXTENDED]
  const whiteList = useMemo(() => {
    return list ? list.tokens.map((t) => t.address.toLowerCase()) : []
  }, [list])
  return whiteList
}

const ARB_PAIR = [
  '0x56b251d4b493ee3956E3f899D36b7290902D2326'.toLowerCase(),
  '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'.toLowerCase(),
]

const CRO_PAIR = [
  '0x97749c9B61F878a880DfE312d2594AE07AEd7656'.toLowerCase(),
  '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59'.toLowerCase(),
]

function getTokenData(chainId: ChainId) {
  if (chainId === ChainId.ARBITRUM) return ARB_PAIR
  if (chainId === ChainId.CRONOS) return CRO_PAIR
  return ARB_PAIR
}

// TODO: replace with proper list
export const useTokenHighLightList = () => {
  const { chainId } = useActiveChainId()
  const bscWhiteList = useBSCWhiteList()
  const allTokensFromBSC = useTokenDatasSWR(getTokenData(chainId), false)
  const allTokensFromETH = useAllTokenHighLight()
  return allTokensFromBSC;
}
